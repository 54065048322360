let initialized = false;

function isDesktop() {
	let desktop = false;
	try {
		desktop = window.matchMedia('screen and (min-width: 1024px)').matches;
	} catch (e) {
		console.warn('error matching media', {error: e});
	}
	return desktop;
}


function init() {
	if (!initialized) {
		const menuButton = document.querySelector('#menu-toggle .menu-button');
		if (menuButton) {
			menuButton.addEventListener('click', (event) => {
				menuButton.classList.toggle('toggled');
				if (isDesktop()) {
					document.body.classList.toggle('hide-offcanvas');
				} else {
					document.body.classList.toggle('show-offcanvas');
				}
				event.preventDefault();
			})
		} else {
			console.warn('could not find #menu-toggle .menu-button');
		}

		const firstLevelLinks = document.querySelectorAll('.sidenav > li > a');
		firstLevelLinks.forEach(link => {
			const hasSubmenu = !!link.parentElement.querySelector('ul.menu_level_1');
			if (hasSubmenu) {
				link.addEventListener('click', function (event) {
					if (!isDesktop() && !link.parentElement.classList.contains('active')) {
						link.parentElement.classList.add('active');
						event.preventDefault();
					}
				});
			}
		});

		initialized = true;
	}
}

module.exports = {
	init
}
